import { logger } from '../logging'

export interface IsRptEnabledProps {
  token: string
  orgId: number
  log: typeof logger
}

export const isRptEnabled = async ({ token, orgId, log }: IsRptEnabledProps): Promise<boolean> => {
  try {
    const response = await fetch(
      `${process.env.NX_REPORTING_API_URL}/users/check-reporting-provisioned?orgId=${orgId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    )

    const json = await response.json()
    return json.hasReportingProvisioned
  } catch (e) {
    log?.error('isRptEnabled error', { orgId }, e as Error)
  }

  return false
}

import React, { FC, useState } from 'react'
import { useController } from 'react-hook-form'

import formMethods from '../../framework/forms/form-methods'
import { addMessage } from './helpers'
import { usePassword } from './hooks/usePassword'
import { StyledTextField } from './input.styles'
import { InputProps } from './input.types'

const VALID_EMAIL_ADDRESS = /^(?:\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(?:\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/

export const Input: FC<InputProps> = ({
  id,
  readOnly,
  startAdornment,
  endAdornment,
  helperText,
  required,
  type,
  rules,
  inputRef,
  error,
  variant,
  InputLabelProps,
  methods,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const { passwordType, passwordIcon } = usePassword()
  const defaultMethods = formMethods()
  const { register, control } = methods || defaultMethods

  // #region Get Rules
  // Calculate pattern based on input type
  let pattern: Record<'value' | 'message', string | RegExp> | undefined
  if (type === 'email')
    pattern = { value: VALID_EMAIL_ADDRESS, message: 'Please enter a valid email' }
  else pattern = undefined

  const defaultRules = {
    required: required ? 'This field is required' : undefined,
    pattern,
  }

  // These messages will be used if user does not provide any
  // for the specified field
  const defaultMessages: [string, (value: any) => string][] = [
    ['minLength', (value: any) => `Please enter ${value} characters or more`],
    ['maxLength', (value: any) => `Please enter ${value} characters or less`],
    ['min', (value: any) => `Please enter ${value} or more`],
    ['max', (value: any) => `Please enter ${value} or less`],
  ]

  // This will collect the user given message if available
  // and if it isn't will insert the default message
  const messages: Record<string, any> = defaultMessages.reduce((acc, message) => {
    const [field, getMessage] = message
    return addMessage(rules, acc, field, getMessage)
  }, {})

  const inputRules = { ...defaultRules, ...rules, ...messages }
  // #endregion

  const {
    field: { onChange, onBlur, value },
    fieldState: { invalid },
    formState: { errors },
  } = useController({
    ...((props.defaultValue && { defaultValue: props.defaultValue }) as any),
    control,
    name: id,
    rules: inputRules,
  })

  // #region Combine User & Hook Form Events
  const onChangeEvent = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.onChange) props.onChange(e)
    onChange(e)
  }
  const onFocusEvent = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(true)
    if (props.onFocus) props.onFocus(e)
  }
  const onBlurEvent = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsFocused(false)
    if (props.onBlur) props.onBlur(e)
    onBlur()
  }
  // #endregion

  const registerField = register(id, inputRules as Record<string, unknown>)

  const calculatedHelperText = invalid ? errors[id]?.message || helperText : helperText

  return (
    <StyledTextField
      fullWidth
      data-testid="input"
      InputProps={{
        readOnly,
        startAdornment,
        endAdornment: type === 'password' ? passwordIcon : endAdornment,
      }}
      InputLabelProps={{
        ...InputLabelProps,
        shrink: isFocused || (value !== null && value !== undefined && value !== ''),
        style: {
          marginLeft: !isFocused && startAdornment ? '1.5rem' : ''
        },
      }}
      helperText={calculatedHelperText}
      id={id}
      required={required}
      type={type === 'password' ? passwordType : type}
      error={error || invalid}
      inputRef={inputRef}
      variant={variant}
      {...registerField}
      {...props}
      onChange={onChangeEvent}
      onFocus={onFocusEvent}
      onBlur={onBlurEvent}
    />
  )
}

import { useAuth0 } from '@auth0/auth0-react'
import { useBusinessServicesLazyQuery } from '@brand-console/generated-graphql-hooks'
import {
  isDFWEnabled,
  isRptEnabled,
  logger,
  useLayoutStore,
  useSfAccount,
  useSfNavigation,
} from '@brand-console/utilities'
import { useCartAuth } from '@cartdotcom/auth'
import { useEffect, useState } from 'react'

export interface EnabledServices {
  enabled: {
    /** true if Multichannel Management is enabled */
    mcmEnabled: boolean
    /** true if Storefront is enabled */
    sfEnabled: boolean
    /** true if Data Feed Watch is enabled */
    dfwEnabled: boolean
    /** true if Fulfillment is enabled */
    ffEnabled: boolean
    /** true if Reporting is enabled */
    rptEnabled: boolean
  }
  loading: boolean
}
/**
 * Checks if the user has access to MCM, Storefront, and DFW.
 * Make sure to pass a logger so errors get sent to the logging service.
 */
export const useEnabledServices = (
  currentOrganizationId: number,
  currentBusinessId: number,
  log: typeof logger,
  {
    skipSf,
    skipDfw,
    skipUa,
    skipMcm,
  }: { skipMcm?: boolean; skipSf?: boolean; skipDfw?: boolean; skipUa?: boolean } = {
    skipMcm: false,
    skipSf: false,
    skipDfw: false,
    skipUa: false,
  },
): EnabledServices => {
  const [fetchingDfw, setFetchingDfw] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const { auth0Id, email } = useCartAuth()
  const [getBusinessServices, { data: businessServices, loading: mcmLoading }] =
    useBusinessServicesLazyQuery()
  const [sfAccount, { loading: sfLoading }] = useSfAccount({
    businessId: currentBusinessId,
    skip: skipSf,
    log,
  })
  const { isEnabled: isSfEnabled } = useSfNavigation(sfAccount)
  const { fulfillmentProvisioned, product } = useLayoutStore()
  const loading = mcmLoading || fetchingDfw || sfLoading

  const [enabled, setEnabled] = useState<EnabledServices['enabled']>({
    mcmEnabled: product === 'mcm' || false,
    sfEnabled: product === 'sf' || isSfEnabled,
    dfwEnabled: product === 'dfw' || false,
    ffEnabled: fulfillmentProvisioned,
    rptEnabled: product === 'rpt' || false,
  })

  useEffect(() => {
    if (!skipMcm && currentBusinessId && product && product !== 'mcm') {
      getBusinessServices({ variables: { orgBusinessShowId: currentBusinessId } })
    }
  }, [currentBusinessId, getBusinessServices, product, skipMcm])

  useEffect(() => {
    setEnabled((prev) => ({ ...prev, ffEnabled: fulfillmentProvisioned }))
  }, [fulfillmentProvisioned])

  useEffect(() => {
    setEnabled((prev) => ({
      ...prev,
      sfEnabled: isSfEnabled,
    }))
  }, [isSfEnabled])

  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    if (businessServices?.org_businessShow?.businessServices?.mcmEnabled != undefined) {
      setEnabled((prev) => ({
        ...prev,
        mcmEnabled: !!businessServices?.org_businessShow?.businessServices?.mcmEnabled,
      }))
    }
  }, [businessServices?.org_businessShow?.businessServices?.mcmEnabled])

  useEffect(() => {
    const fetchServices = async () => {
      const token = await getAccessTokenSilently()
      const promises = []
      const results: Partial<EnabledServices['enabled']> = {}

      if (!skipDfw && product && product !== 'dfw') {
        promises.push(
          isDFWEnabled({ email, token, log }).then((res) => {
            results.dfwEnabled = res
          }),
        )
        setFetchingDfw(true)
      }

      await Promise.all(promises)

      setFetchingDfw(false)
      setEnabled((prev) => ({
        ...prev,
        ...results,
      }))
    }
    fetchServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusinessId, getAccessTokenSilently, email, auth0Id, product])

  useEffect(() => {
    const fetchServices = async () => {
      const token = await getAccessTokenSilently()
      let rptEnabled = false

      if (!skipUa && currentOrganizationId && product && product !== 'rpt') {
        rptEnabled = await isRptEnabled({ orgId: currentOrganizationId, token, log })
      }

      setFetchingDfw(false)
      setEnabled((prev) => ({
        ...prev,
        rptEnabled,
      }))
    }
    fetchServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusinessId, getAccessTokenSilently, email, auth0Id, product])

  return { enabled, loading }
}

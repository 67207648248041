/* eslint-disable import/no-cycle */
import { useReactiveVar } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { Alert, Button, Card, CardContent, CardFooter } from '@cart/ui'
import { useCartAuth } from '@cartdotcom/auth'
import React, { ReactElement, useEffect, useState } from 'react'
import TagManager from 'react-gtm-module'

import { ChangePasswordModal } from './modals/ChangePasswordModal'
import { ConfirmPasswordModal } from './modals/ConfirmPasswordModal'
import { EnableOTPModal } from './modals/EnableOTPModal'
import { EnableSMSModal } from './modals/EnableSMSModal'
import { ManageMFAModal } from './modals/ManageMFAModal'
import { RecoveryCodeModal } from './modals/RecoveryCodeModal'
import { RemoveMFAModal } from './modals/RemoveMFAModal'
import { SessionsLogoutModal } from './modals/SessionsLogoutModal'
import { mfaTokenVar, recoveryCodeVar } from './SettingsSecurity.partials'

export const SettingsSecurity = (): ReactElement => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'SettingsSecurity',
      },
    })
  }, [])
  const { loginWithRedirect } = useAuth0()
  const { email } = useCartAuth()
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [showManageMFAModal, setShowManageMFAModal] = useState(false)
  const [showEnableOtpModal, setShowEnableOtpModal] = useState(false)
  const [showEnableSmsModal, setShowEnableSmsModal] = useState(false)
  const [showRemoveMfaModal, setShowRemoveMfaModal] = useState(false)
  const [showRecoveryCodeModal, setShowRecoveryCodeModal] = useState(false)
  const [showConfirmPasswordModal, setShowConfirmPasswordModal] = useState(false)
  const [showSessionsLogoutModal, setShowSessionsLogoutModal] = useState(false)
  const [modalToShow, setModalToShow] = useState('')
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showFailureAlert, setShowFailureAlert] = useState(false)
  const [successAlertMessage, setSuccessAlertMessage] = useState('Success alert text!')

  const recoveryCode = useReactiveVar(recoveryCodeVar)
  const mfaToken = useReactiveVar(mfaTokenVar)

  const handleTokenErrorCallback = (modalToReshow: string) => {
    setShowConfirmPasswordModal(true)
    setModalToShow(modalToReshow)
  }

  useEffect(() => {
    // if we have a recovery code from one of the mfa enable paths and they have since closed, now present the recovery code.
    if (!showEnableSmsModal && !showEnableOtpModal && recoveryCode !== '') {
      setShowRecoveryCodeModal(true)
    } else {
      setShowRecoveryCodeModal(false)
    }
  }, [showEnableOtpModal, showEnableSmsModal, recoveryCode])

  useEffect(() => {
    // if we have a token and the confirmpasswordmodal closed and we have a modal to show now, we show it.
    if (!showConfirmPasswordModal && mfaToken !== '') {
      switch (modalToShow) {
        case 'enableotp':
          setShowEnableOtpModal(true)
          break
        case 'enablesms':
          setShowEnableSmsModal(true)
          break
        case 'manage':
          setTimeout(() => setShowManageMFAModal(true), 300)
          break
        case 'remove':
          setShowRemoveMfaModal(true)
          break
        case 'recovery':
          setShowRecoveryCodeModal(true)
          break
        default:
          break
      }
    }
  }, [modalToShow, showConfirmPasswordModal, mfaToken])

  const handleButtonClickCallback = () => {
    mfaTokenVar('')
    setShowConfirmPasswordModal(true)
    setModalToShow('manage')
  }

  return (
    <div tw="m-auto max-w-5xl px-3 sm:px-20">
      <ChangePasswordModal
        show={showChangePasswordModal}
        successCallback={() => {
          setSuccessAlertMessage('Your password was changed successfully!')
          setShowSuccessAlert(true)
          setShowChangePasswordModal(false)
          setTimeout(() => {
            loginWithRedirect({
              authorizationParams: {
                screen_hint: 'login',
                login_hint: email,
              },
            })
          }, 3000)
        }}
        closeCallback={() => setShowChangePasswordModal(false)}
      />

      <ManageMFAModal
        show={showManageMFAModal}
        setModalToShowCallback={(modal: string) => {
          setModalToShow(modal)
        }}
        closeCallback={() => setShowManageMFAModal(false)}
      />

      <ConfirmPasswordModal
        show={showConfirmPasswordModal}
        closeCallback={() => setShowConfirmPasswordModal(false)}
      />

      <EnableOTPModal
        show={showEnableOtpModal}
        closeCallback={() => setShowEnableOtpModal(false)}
        // setRecoveryCodeCallback={(code: string) => setRecoveryCode(code)}
        handleTokenErrorCallback={(modalToReshow: string) =>
          handleTokenErrorCallback(modalToReshow)
        }
        successCallback={(successMessage: string) => {
          setSuccessAlertMessage(successMessage)
          setShowSuccessAlert(true)
        }}
      />
      <EnableSMSModal
        show={showEnableSmsModal}
        closeCallback={() => setShowEnableSmsModal(false)}
        // setRecoveryCodeCallback={(code: string) => setRecoveryCode(code)}
        handleTokenErrorCallback={(modalToReshow: string) =>
          handleTokenErrorCallback(modalToReshow)
        }
        successCallback={(successMessage: string) => {
          setSuccessAlertMessage(successMessage)
          setShowSuccessAlert(true)
        }}
      />
      <RemoveMFAModal
        show={showRemoveMfaModal}
        closeCallback={() => setShowRemoveMfaModal(false)}
        handleTokenErrorCallback={(modalToReshow: string) =>
          handleTokenErrorCallback(modalToReshow)
        }
        successCallback={(successMessage: string) => {
          setSuccessAlertMessage(successMessage)
          setShowSuccessAlert(true)
        }}
      />
      <RecoveryCodeModal
        show={showRecoveryCodeModal}
        closeCallback={() => {
          setShowRecoveryCodeModal(false)
        }}
      />

      <SessionsLogoutModal
        show={showSessionsLogoutModal}
        successCallback={() => {
          setSuccessAlertMessage('All sessions ended successfully!')
          setShowSuccessAlert(true)
          setTimeout(() => {
            loginWithRedirect({
              authorizationParams: {
                screen_hint: 'login',
                login_hint: email,
              },
            })
          }, 3000)
        }}
        closeCallback={() => setShowSessionsLogoutModal(false)}
      />

      {showSuccessAlert && (
        <Alert severity="success" tw="mb-9" onClose={() => setShowSuccessAlert(false)}>
          {successAlertMessage}
        </Alert>
      )}
      {showFailureAlert && (
        <Alert severity="error" onClose={() => setShowFailureAlert(false)}>
          An unspecified error occurred.
        </Alert>
      )}

      <h2 tw="mt-8 mb-8">Security</h2>

      <div tw="mb-8 w-full">
        <div tw="block align-top lg:(inline-block w-3/4)">
          <Card>
            <CardContent>
              <h4 tw="mb-2 text-base">Password</h4>
              <p tw="text-sm">Manage your password</p>
            </CardContent>
            <CardFooter tw="border-monochrome-300 border-t">
              <Button tw="m-2" variant="outlined" onClick={() => setShowChangePasswordModal(true)}>
                Change password
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
      <div tw="mb-8 w-full">
        <div tw="block align-top lg:(inline-block w-3/4)">
          <Card>
            <CardContent>
              <h5 tw="mb-2 text-base">Multi-factor authentication (MFA)</h5>
              <p tw="text-sm">Add an extra layer of security to your account when you log in.</p>
            </CardContent>
            <CardFooter tw="border-monochrome-300 border-t">
              <Button tw="m-2" variant="outlined" onClick={() => handleButtonClickCallback()}>
                Manage multi-factor authentication
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
      <div tw="mb-8">
        <div tw="block align-top lg:(inline-block w-3/4)">
          <Card>
            <CardContent>
              <h5 tw="mb-2 text-base">Session management</h5>
              <p tw="text-sm">
                This user will be logged out of all the other active sessions expect this one.
              </p>
            </CardContent>
            <CardFooter tw="border-monochrome-300 border-t">
              <Button tw="m-2" variant="outlined" onClick={() => setShowSessionsLogoutModal(true)}>
                Sign out of all sessions
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  )
}

import {
  isAtLeastBizAdmin,
  useCurrentContext,
  useIsCsr,
  usePermissionCheck,
} from '@cartdotcom/auth'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Menu } from '../../Menu/Menu'
import { showHideMenuItems } from '../Shared/visibility.helper'
import { addBusinessIdParam } from './McmMenu.helpers'
import { mcmBaseMenu } from './McmMenu.menu'
import { McmMenuIds, McmMenuItemsVisibility, McmOrdersUrls } from './McmMenu.types'

export const McmMenu = () => {
  const {
    mcmTempEnableCoreOrders,
    mcmPricingManagerUsers,
    mcmTempProductOpportunitiesReport20240401, //mcm-temp-product-opportunities-report-20240401
    mcmTempLowStockReport, //mcm-temp-low-stock-report
    mcmProductContentChanges
  } = useFlags()
  const { currentOrganization, currentBusiness, roleName } = useCurrentContext()

  const isCsr = useIsCsr()

  const hasEmailSubscriptionsAccess = usePermissionCheck(
    'emailSubscriptions',
    currentBusiness.id,
    currentOrganization.id,
  )

  const urlParams = new URLSearchParams(window.location.search)
  const isMcmNav = urlParams.get('nav') === 'mcm'
  const baseUrl = isMcmNav ? `${process.env.NX_MCM_ORIGIN}` : ''

  const menu = currentBusiness?.id ? mcmBaseMenu(baseUrl) : []
  // enhance menu with options

  if (menu.length) {
    const mcmMenu = menu.find(item => item.id === McmMenuIds.MCM);
    const mcmSettingsMenu = menu.find(item => item.id === McmMenuIds.MCM_SETTINGS);

    mcmMenu.items.find((x) => x.id === McmMenuIds.ORDERS).url = mcmTempEnableCoreOrders
      ? McmOrdersUrls.BC
      : McmOrdersUrls.LEGACY

    const reportsMenuItems = mcmMenu.items.find(subItem => subItem.id === McmMenuIds.REPORTS)?.items ?? [];
    const settingsMenuItems = mcmSettingsMenu.items.find(subItem => subItem.id === McmMenuIds.SETTINGS)?.items ?? [];

    const menuItemVisibilityChecks: McmMenuItemsVisibility[] = [
      {
        menuItems: reportsMenuItems,
        visibility: {
          [McmMenuIds.PRODUCT_OPPORTUNITIES]: mcmTempProductOpportunitiesReport20240401,
          [McmMenuIds.LOW_STOCK_REPORT]: mcmTempLowStockReport,
          [McmMenuIds.PRODUCT_CONTENT_CHANGES_REPORT]: mcmProductContentChanges,
        }
      },
      {
        menuItems: mcmSettingsMenu.items,
        visibility: {
          [McmMenuIds.LEGACY]: mcmPricingManagerUsers,
          [McmMenuIds.ADMIN]: isCsr
        }
      },
      {
        menuItems: settingsMenuItems,
        visibility: {
          [McmMenuIds.API]: isAtLeastBizAdmin(roleName),
          [McmMenuIds.FORECAST_SETTINGS]: mcmTempLowStockReport,
          [McmMenuIds.EMAILS]: hasEmailSubscriptionsAccess,
        },
      },
    ]

    for (const check of menuItemVisibilityChecks) {
      showHideMenuItems(check.menuItems, check.visibility)
    }

    if (isMcmNav) {
      // for redirect back to MCM, pass the businessId in case it's changed
      menu.forEach((m) => addBusinessIdParam(m, currentBusiness?.id))
    }
  }

  return <Menu menuSections={menu} />
}

import tw, { css, styled } from 'twin.macro'

export const StyledContainer = styled.div(() => [
  tw`[grid-template-columns: auto 1fr] [grid-template-rows: auto 1fr] grid min-h-screen gap-0 bg-monochrome-50`,
  css`
    grid-template-areas:
      'Header Header .'
      'Left-Nav Body .'
      'Left-Nav Footer .';
    & .Header {
      grid-area: Header;
      ${tw`sticky top-0 z-20 h-auto`}
      @media (display-mode: standalone) {
        ${tw`[padding-top: env(safe-area-inset-top)] bg-black`}
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 427.97 57.03'%3E%3Cpath d='m243.67 0H0v57.03h227.08c4.76-18.83 9.04-37.79 16.3-55.89.14-.36.22-.74.28-1.13Z' fill='%23175882'/%3E%3Cpath d='m280.08 57.03h67.49c15.92-20.89 37.23-33.68 62.38-40.67 6-1.67 12.01-3.29 18.02-4.92V0h-115.83c-7.35 8.58-14.47 17.28-20.05 27.22-5.32 9.47-9.2 19.4-12.01 29.81Z' fill='%23df8935'/%3E%3Cpath d='m243.39 1.13c-7.26 18.1-11.54 37.07-16.3 55.89h53c2.81-10.4 6.7-20.34 12.01-29.81 5.58-9.94 12.7-18.64 20.05-27.22h-68.47c-.07.39-.14.78-.28 1.13Z' fill='%23d56e27'/%3E%3Cpath d='m347.57 57.03h80.4V11.43c-6.01 1.64-12.02 3.26-18.02 4.92-25.15 6.99-46.45 19.78-62.38 40.67Z' fill='%23e6a867'/%3E%3C/svg%3E");
      }
    }
    & .Left-Nav {
      grid-area: Left-Nav;
      ${tw`[z-index: 2] [top: 64px] [height: calc(100vh - 64px)] sticky self-start mobile:z-50`}
    }
    & .Body {
      grid-area: Body;
      max-width: 100vw;
      overflow: auto;
    }
    & .Footer {
      grid-area: Footer;
    }
  `,
])

import { logger, useLayoutStore } from '@brand-console/utilities'
import { Button } from '@cart/ui/atoms/Button'
import { useBreakpoint } from '@cart/ui/hooks/useBreakpoint'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Collapse from '@mui/material/Collapse'
import MenuList from '@mui/material/MenuList'
import React, { useMemo, useRef, useState } from 'react'
import tw from 'twin.macro'

import {
  StyledMenu,
  StyledMenuSection,
  StyledMenuSectionContent,
  StyledMenuTitle,
} from '../../header.styles'
import { AppItem } from './AppItem'
import { getDiscoverItems } from './AppsDropdown.constants'
import { useEnabledItems } from './hooks/useEnabledItems'

export const AppsDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [moreExpanded, setMoreExpanded] = React.useState(false)
  const { fulfillmentProvisioned } = useLayoutStore()
  const log = useMemo(() => logger.setLogger(), [])

  const open = Boolean(anchorEl)
  const menuId = 'apps-dropdown'
  const triggerId = 'apps-dropdown-trigger'
  const menuHeaderId = 'app-dropdown-header'
  const menuRef = useRef(null)
  const isMobile = !useBreakpoint('sm')

  const DISCOVER_ITEMS = useMemo(
    () =>
      getDiscoverItems({
        shouldShowFulfillment: fulfillmentProvisioned,
      }),
    [fulfillmentProvisioned],
  )

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setMoreExpanded(false)
  }

  const handleExpandClick = () => {
    setMoreExpanded(!moreExpanded)
  }

  const handleMenuItemClick = (onClick?: () => void) => () => {
    onClick?.()
    handleClose()
  }

  const [enabledItems, disabledItems] = useEnabledItems(log)

  return (
    <div>
      <Button
        onClick={handleOpen}
        type="button"
        square
        iconButton={false}
        id={triggerId}
        data-testid="apps-dropdown-trigger"
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-label="Open apps dropdown"
        css={[open ? tw`!bg-black/[0.3]` : '']}
      >
        <FontAwesomeIcon tw="text-xl text-monochrome-900/[0.6]" icon={solid('grid')} />
      </Button>
      <StyledMenu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO:  This is valid but due to a bug in material-ui component is not accepted https://github.com/mui/material-ui/issues/32872
          component: 'div',
        }}
        slotProps={{
          paper: {
            elevation: 8,
          },
        }}
        transformOrigin={{ horizontal: isMobile ? 'center' : 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: isMobile ? 'center' : 'right', vertical: 'bottom' }}
        role="menu"
        aria-labelledby={menuHeaderId}
        tw="pb-0 [& .MuiMenu-list]:py-0"
        ref={menuRef}
      >
        <div tw="flex flex-col text-sm max-h-[calc(100vh - 200px)] [@media(min-height:900px)]:max-h-[640px]">
          <StyledMenuSection css={[tw`px-6 py-4 text-center`]} borderBottom>
            <span id={menuHeaderId} tw="font-heading font-semibold">
              Apps & Services
            </span>
          </StyledMenuSection>
          <section tw="[max-height: 500px]" className="scrollable-mask">
            {enabledItems.length > 0 && (
              <StyledMenuSectionContent
                data-testid="appsAndServicesLaunchMenu"
                borderBottom={!!disabledItems.length || moreExpanded}
              >
                <StyledMenuTitle>Launch</StyledMenuTitle>
                <MenuList role="group" component="div">
                  {enabledItems.map(({ title, icon, link, onClick }) => (
                    <AppItem
                      key={title}
                      title={title}
                      Icon={icon}
                      handleClick={handleMenuItemClick(onClick)}
                      href={link}
                    />
                  ))}
                </MenuList>
              </StyledMenuSectionContent>
            )}
            {disabledItems.length > 0 && (
              <StyledMenuSectionContent borderBottom={moreExpanded}>
                <StyledMenuTitle>Suggested</StyledMenuTitle>
                <MenuList role="group" component="div">
                  {disabledItems.map(({ title, icon, link, onClick }) => (
                    <AppItem
                      key={title}
                      title={title}
                      Icon={icon}
                      href={link}
                      handleClick={handleMenuItemClick(onClick)}
                    />
                  ))}
                </MenuList>
              </StyledMenuSectionContent>
            )}
            <Collapse in={moreExpanded}>
              <StyledMenuSectionContent tw="pb-2">
                <StyledMenuTitle> More from Cart </StyledMenuTitle>
                <MenuList role="group" component="div">
                  {DISCOVER_ITEMS.map(({ title, icon, link, desc }) => (
                    <AppItem
                      key={title}
                      title={title}
                      Icon={icon}
                      href={link}
                      handleClick={handleMenuItemClick()}
                      desc={desc}
                      variant="discover"
                    />
                  ))}
                </MenuList>
              </StyledMenuSectionContent>
            </Collapse>
          </section>
          <StyledMenuSection borderTop>
            <div tw="p-2">
              <Button fullWidth onClick={handleExpandClick}>
                {moreExpanded ? 'Show Less' : 'Discover More'}
              </Button>
            </div>
          </StyledMenuSection>
        </div>
      </StyledMenu>
    </div>
  )
}
